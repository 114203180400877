import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./Slider.css";
interface Media {
  mediaURL?: string;
  mediaAlt?: string;
  mediaLink?: string;
}

export default function Slider({
  sliderConfig,
  anchorID,
}: Record<string, any>): JSX.Element {
  const images =
    sliderConfig?.media?.map((media: Media) => {
      return {
        original: media.mediaURL,
        originalAlt: media.mediaAlt,
        originalClass: "SliderDiv",
        link: media.mediaLink,
      };
    }) ?? [];

  return (
    <section
      id={anchorID}
      data-testid="slider-section"
    >
      <ImageGallery
        items={images.map(
          (image: {
            link: string | undefined;
            original: string | undefined;
            originalAlt: string;
            originalClass: string;
          }) => ({
            ...image,
            renderItem: () =>
              image.link ? (
                <a
                  key={image.original}
                  href={image.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={image.link}
                >
                  <img
                    style={{ width: "100%" }}
                    src={image.original}
                    alt={image.originalAlt}
                  />
                </a>
              ) : (
                <img
                  key={image.original}
                  className="withoutPointer"
                  style={{ width: "100%" }}
                  src={image.original}
                  alt={image.originalAlt}
                />
              ),
          })
        )}
        showPlayButton={false}
        showFullscreenButton={false}
        autoPlay={true}
        slideInterval={sliderConfig.interval ?? 5000}
        showThumbnails={false}
        showNav={false}
        lazyLoad={true}
      />
    </section>
  );
}
